import { Link } from 'gatsby';
import React from 'react';
import BlogPostPreview from '../blog-post-preview/blog-post-preview';
import { buildImageObj, cn, getBlogUrl } from '../../../lib/helpers';
import { imageUrlFor } from '../../../lib/image-url';
import styles from './blog-post-preview-grid.module.css';
import PortableText from '../portableText';

function BlogPostPreviewGrid(props) {
  return (
    <div className={styles.root}>
      {/* {props.nodes && (
        <div className={styles.coverBlock}>
          {props.nodes[0].mainImage && props.nodes[0].mainImage.asset && (
            <img
              src={imageUrlFor(buildImageObj(props.nodes[0].mainImage))
                .width(600)
                .height(Math.floor((9 / 16) * 600))
                .auto('format')
                .url()}
              alt={props.nodes[0].mainImage.alt}
            />
          )}
          <div className={styles.coverBlockText}>
            <span>{format(props.nodes[0].publishedAt, 'MMMM Do, YYYY')}</span>
            <h3>{props.nodes[0].title}</h3>
            <p>
              <PortableText blocks={props.nodes[0]._rawExcerpt} />
            </p>
          </div>
        </div>
      )} */}
      {props.title && <h2 className={styles.headline}>{props.title}</h2>}
      <ul className={styles.grid}>
        {props.nodes &&
          props.nodes.map((node) => (
            <li key={node.id}>
              <BlogPostPreview {...node} />
            </li>
          ))}
      </ul>
      {props.browseMoreHref && (
        <div className={styles.browseMoreNav}>
          <Link to={props.browseMoreHref}>Browse more</Link>
        </div>
      )}
    </div>
  );
}

BlogPostPreviewGrid.defaultProps = {
  title: '',
  nodes: [],
  browseMoreHref: '',
};

export default BlogPostPreviewGrid;
